// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * This is the new reference from the latest Figma design
 * Please find the color in figma, search if it isn't already 
 * here, if so use it, if not add it with clear name
 */
section#transaction-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  margin: 0px auto;
  width: 100%;
}
section#transaction-component form.inputs {
  align-self: center;
  width: 100%;
}
section#transaction-component .au-stagger {
  animation-delay: 0s;
}
section#transaction-component div.all_tx {
  flex-grow: 1;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
section#transaction-component div.all_tx div.transaction {
  align-items: center;
  border-bottom: 1px solid var(--stroke-dark-base, #2F3340);
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
section#transaction-component div.all_tx div.transaction div.top {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
section#transaction-component div.all_tx div.transaction div.bottom {
  text-align: center;
  width: 100%;
}
section#transaction-component div.all_tx div.transaction div.bottom p.comment {
  font-size: medium;
  font-weight: 400;
  margin: 1rem 0;
}
section#transaction-component div.all_tx div.transaction p {
  margin: 0rem;
  padding: 0rem;
}
section#transaction-component div.all_tx div.transaction div.picture {
  display: flex;
  flex: 2;
  flex-direction: row;
  padding: 0.75rem 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
section#transaction-component div.all_tx div.transaction div.picture div.name_date_container span.name {
  color: var(--text-icons-dark-base-main, #F9F9F9);
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.375rem;
  font-family: "Inter";
}
section#transaction-component div.all_tx div.transaction div.picture div.name_date_container span.date {
  color: var(--text-icons-dark-base-main, #F9F9F9);
  display: inline;
  font-size: 0.75rem;
  max-height: 5rem !important;
}
section#transaction-component div.all_tx div.transaction div.picture img {
  border-radius: 50%;
  height: 2rem;
  margin: 0.4rem 1rem 0 0;
  width: 2rem;
}
section#transaction-component div.all_tx div.transaction div.amount {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-size: 0.875rem;
  font-family: "Inter";
  font-weight: 500;
  line-height: 1.25rem;
  padding-right: 1rem;
  text-align: right;
}
section#transaction-component div.all_tx div.transaction p.date {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  font-size: 0.875rem;
  font-family: "Inter";
  font-weight: 500;
  line-height: 1.25rem;
  flex: 1;
  padding: 0.75rem 1rem;
  max-width: 80rem;
  transition: text-align 0.1s ease, max-width 0.1s ease;
}
section#transaction-component div.all_tx div.transaction p.account {
  color: var(--text-icons-dark-base-second, #AFB3C0);
  flex: 1;
  font-size: 0.875rem;
  font-family: "Inter";
  font-weight: 500;
  line-height: 1.25rem;
  overflow: hidden;
  max-width: 80rem;
  transition: max-width 0.1s ease;
}
section#transaction-component div.all_tx div.transaction p.action {
  overflow: hidden;
  max-width: 80rem;
  width: 2rem;
}
section#transaction-component div.empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section#transaction-component div.empty p {
  color: var(--text-icons-dark-base-second, #F9F9F9);
  font-style: italic;
  font-family: "Inter";
  margin-bottom: 0;
  margin-left: 1rem;
}

@media only screen and (min-width: 60rem) {
  section#transaction-component div.transactions_products div.icon {
    height: 3.5rem;
    width: 3.5rem;
  }
  section#transaction-component div.transactions_products div.icon .wallet {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
  section#transaction-component div.transactions_products div.transactions_date_and_product p.transaction_name {
    max-height: 2.6rem !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
@media only screen and (max-width: 40rem) {
  section#transaction-component div.transaction p.picture {
    flex: 2;
    line-height: 1rem;
  }
  section#transaction-component div.transaction p.picture img {
    border-radius: 50%;
    float: left;
    height: 3rem !important;
    width: 3rem !important;
  }
  section#transaction-component div.transaction p.picture span.name {
    font-size: 1.15rem !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  section#transaction-component div.transaction p.picture span.date {
    color: var(--text-icons-dark-base-main, #F9F9F9);
    display: inline;
    font-size: 0.75rem;
    max-height: 5rem !important;
  }
  section#transaction-component div.transaction p.account {
    max-width: 65% !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  section#transaction-component div.transaction p.action {
    opacity: 0 !important;
    padding: 0rem !important;
    max-width: 0rem !important;
  }
}
@supports (-webkit-touch-callout: none) {
  section#transaction-component div.all_tx {
    margin-bottom: 3rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/colors.scss","webpack://./src/components/transaction-component/transaction-component.scss"],"names":[],"mappings":"AAAA;;;;EAAA;ACEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;EACA,WAAA;AAIF;AAFE;EACE,kBAAA;EACA,WAAA;AAIJ;AADE;EACE,mBAAA;AAGJ;AAAE;EACE,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AAEJ;AAAI;EACE,mBAAA;EACA,yDAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AAEN;AAAM;EACE,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AAER;AACM;EACE,kBAAA;EACA,WAAA;AACR;AACQ;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;AACV;AAGM;EACE,YAAA;EACA,aAAA;AADR;AAIM;EACE,aAAA;EACA,OAAA;EACA,mBAAA;EACA,qBAAA;EACA,uBAAA;EACA,gBAAA;AAFR;AAKU;EACE,gDAAA;EACA,oBAAA;EACA,gBAAA;EACA,qBAAA;EACA,oBDmCa;ACtCzB;AAMU;EACE,gDAAA;EACA,eAAA;EACA,kBAAA;EACA,2BAAA;AAJZ;AAQQ;EACE,kBAAA;EACA,YAAA;EACA,uBAAA;EACA,WAAA;AANV;AASM;EACE,kDAAA;EACA,mBAAA;EACA,oBDciB;ECbjB,gBAAA;EACA,oBAAA;EACA,mBAAA;EACA,iBAAA;AAPR;AASM;EACE,kDAAA;EACA,mBAAA;EACA,oBDKiB;ECJjB,gBAAA;EACA,oBAAA;EACA,OAAA;EACA,qBAAA;EACA,gBAAA;EACA,qDAAA;AAPR;AASM;EACE,kDAAA;EACA,OAAA;EACA,mBAAA;EACA,oBDPiB;ECQjB,gBAAA;EACA,oBAAA;EACA,gBAAA;EACA,gBAAA;EACA,+BAAA;AAPR;AASM;EACE,gBAAA;EACA,gBAAA;EACA,WAAA;AAPR;AAYE;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAVJ;AAWI;EACE,kDAAA;EACA,kBAAA;EACA,oBD9BmB;EC+BnB,gBAAA;EACA,iBAAA;AATN;;AAeA;EAGM;IACE,cAAA;IACA,aAAA;EAdN;EAgBM;IACE,yBAAA;IACA,wBAAA;EAdR;EAmBM;IACE,6BAAA;IACA,uBAAA;IACA,gBAAA;EAjBR;AACF;AAuBA;EAIM;IACE,OAAA;IACA,iBAAA;EAxBN;EA0BM;IACE,kBAAA;IACA,WAAA;IACA,uBAAA;IACA,sBAAA;EAxBR;EA0BM;IACE,6BAAA;IACA,mBAAA;IACA,gBAAA;IACA,uBAAA;EAxBR;EA0BM;IACE,gDAAA;IACA,eAAA;IACA,kBAAA;IACA,2BAAA;EAxBR;EA2BI;IACE,yBAAA;IACA,gBAAA;IACA,uBAAA;EAzBN;EA2BI;IACE,qBAAA;IACA,wBAAA;IACA,0BAAA;EAzBN;AACF;AA8BA;EAGI;IACE,mBAAA;EA9BJ;AACF","sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
